import React from "react";
import styled from "styled-components";
import ShadowCard from "./shadow-card";
import SubTitle from "./subtitle";
import { THEME_MAIN_COLOR } from "../config/Config";

const Div = styled.div`
  margin: 0 24px;

  ul {
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      margin: 0;
      text-align: center;
      flex-grow: 1;
      flex-shrink: 0;
      padding: 4px;

      width: 25%;
      min-width: 220px;

      .balloon {
        background: ${THEME_MAIN_COLOR};
        border-radius: 50%;
        height: 200px;
        width: 200px;
        margin: 0 auto;
        color: #fff;
        position: relative;

        .balloon__inner {
          position: absolute;
          top: 50%;
          width: 100%;
          transform: translateY(-50%);
        }
      }
    }
  }
`;

const MedicalTarget = () => (
  <ShadowCard>
    <SubTitle>お気軽にご相談ください</SubTitle>
    <Div>
      <ul>
        <li>
          <div className="balloon">
            <p className="balloon__inner">
              めまい
              <br />
              難聴
            </p>
          </div>
        </li>
        <li>
          <div className="balloon">
            <p className="balloon__inner">
              耳垢をとりたい
              <br />
              補聴器の相談
            </p>
          </div>
        </li>
        <li>
          <div className="balloon">
            <p className="balloon__inner">
              いびき
              <br />
              無呼吸
            </p>
          </div>
        </li>
        <li>
          <div className="balloon">
            <p className="balloon__inner">
              はな・くち・のどの
              <br />
              病気について
              <br />
              <span style={{ fontSize: 16 }}>(かぜ症状から腫瘍まで)</span>
            </p>
          </div>
        </li>
      </ul>
    </Div>
  </ShadowCard>
);

export default MedicalTarget;
