import React from "react";

import Layout from "../components/layout";
import Section from "../components/section";
import TimeTableWrapper from "../components/time-table-wrapper";
import BasicInformation from "../components/basic-information";
import MedicalTarget from "../components/medical-target";

const GuidePage = () => (
  <Layout>
    <Section>
      <MedicalTarget />
    </Section>
    <Section>
      <TimeTableWrapper />
    </Section>
    <Section>
      <BasicInformation />
    </Section>
  </Layout>
);

export default () => <GuidePage />;
